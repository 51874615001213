<template>
    <div @contextmenu.prevent="setMousePosition">
        <div>
            <!-- Add a loading overlay pane -->
            <div
                id="sidebar-menu-content"
                class="b-sidebar-outer"
                v-bind:class="{ 'sidebar-menu-iframe': isIframe, 'sidebar-menu': !isIframe }"
                style="background-color: var(--sidemenu-background-color) !important; overflow-y: auto"
                :style="{
                    width: $store.state.sideMenuSize + 'px !important',
                    'margin-top': $store.state.topMenuSize + 'px !important',
                    height: 'calc(100% - ' + $store.state.topMenuSize + 'px - ' + $store.state.bottomMenuSize + 'px)',
                    'max-height': 'calc(100% - ' + $store.state.topMenuSize + 'px - ' + $store.state.bottomMenuSize + 'px)',
                }"
            >
                <div id="sidebar-menu " tabindex="-1" aria-modal="false" role="dialog" style="background-color: var(--sidemenu-background-color) !important" no-close-on-route-change visible>
                    <div
                        v-if="loading"
                        class="loading-overlay"
                        :style="{
                            width: $store.state.sideMenuSize + 'px !important',
                        }"
                    >
                        <div
                            class="loading-spinner"
                            :style="{
                                left: $store.state.sideMenuSize / 2 + 'px !important',
                            }"
                        >
                            <div class="loading-spinner-inner"></div>
                        </div>
                    </div>
                    <div class="b-sidebar-body">
                        <div style="height: 100%; display: flex; flex-direction: column; align-items: stretch; width: 100px!">
                            <ZoomControl v-if="!isFirefox" />
                            <DxTreeView
                                id="treeview-equipments"
                                class="conf-treeview"
                                ref="treeViewEquipments"
                                :create-children="createChildren"
                                :root-value="''"
                                :expand-nodes-recursive="false"
                                data-structure="plain"
                                item-template="item-template"
                                @item-click="
                                    (event) => {
                                        onItemSelected(event, 'click');
                                    }
                                "
                                @item-expanded="
                                    (event) => {
                                        onItemSelected(event, 'expanded');
                                    }
                                "
                                @item-collapsed="
                                    (event) => {
                                        onItemSelected(event, 'collapsed');
                                    }
                                "
                                @item-context-menu="openContextMenu"
                            >
                                <template #item-template="item">
                                    <div>
                                        <div v-if="!item.data.editing"><font-awesome-icon v-if="item.data.icon" :icon="item.data.icon" :color="item.data.iconColor" fixed-width /> {{ item.data.text }}</div>
                                        <div v-else>
                                            <b-form-input v-model="item.data.text" @keyup.enter="onItemEditValidated(item.data)" @keyup.esc="onItemEditCanceled(item.data)" autofocus style="width: 160px; height: 1.2rem; font-size: 0.8rem; display: inline-block" />
                                            <font-awesome-icon icon="check" class="ml-1" fixed-width color="green" @click="onItemEditValidated(item.data)" />
                                            <font-awesome-icon icon="times" fixed-width color="red" @click="onItemEditCanceled(item.data)" />
                                        </div>
                                    </div>
                                </template>
                            </DxTreeView>
                            <!-- <TreeView style="margin-top: 100px;" id="data-treeview" :item="equipments"
                                :itemSelect="selectItem" :onContextMenu="onTreeViewContextMenu"
                                :onItemEditValidated="onItemEditValidated" :multiSelect="true" :checked="false"
                                ref="treeViewEquipments" /> -->
                        </div>
                    </div>
                </div>
            </div>

            <b-modal id="modal-dupli" :title="this.$t('Duplication.Title')" @ok="this.duplication" size="lg">
                <b-form v-if="elementToDuplicate != null && elementToDuplicate != undefined">
                    <div v-if="elementToDuplicate.itemData.itemType == 'equipment'">{{ this.$t('Duplication.Equipment') }} "{{ this.elementToDuplicate.itemData.text }}"</div>
                    <div v-else>{{ this.$t('Duplication.Action') }} "{{ this.elementToDuplicate.itemData.text }}"</div>
                    <br />
                    <div>{{ this.$t('Duplication.Name') }} :</div>
                    <b-form-input v-model="nameDuplicate"></b-form-input>
                </b-form>
            </b-modal>
            <b-modal id="modal-export" :title="this.$t('Duplicate.Title')" @ok="this.export" size="lg">
                  
                <b-form v-if="elementToExport != null && elementToExport != undefined">
                    <b-row>
                        <b-col cols="10">
                            <div v-if="elementToExport.itemData.itemType == 'equipment'">{{ this.$t('Export.Equipment') }} "{{ this.elementToExport.itemData.text }}"</div>
                            <div v-else>{{ this.$t('Duplicate.Action') }} <b>"[{{ this.EqExportOrigin }}]{{ this.elementToExport.itemData.text }}"</b></div>
                        </b-col>
                    </b-row>
                    
                    <hr>
                    <b-form-group :label="$t('Destination')" label-for="ProjectSelector">
                        <DxSelectBox
                            id="ProjectSelector"
                            :items="ProjectsModalExport"
                            display-expr="name"
                            value-expr="id"
                            v-model="valueProjectModalExport"
                            @value-changed="OnValueProjectChangedModalExport"
                        />
                        <DxSelectBox
                            :items="EqsModalExport"
                            v-model="valueEqModalExport"
                            @value-changed="OnValueEqChangedModalExport"
                        />
                        <DxSelectBox
                            :items="GrpActionModalExport"
                            v-model="valueGrpActionModalExport"
                            @value-changed="OnValueGrpActionChangedModalExport"
                            :accept-custom-value="true"
                        />
                    </b-form-group>

                    <br />
                    <div>{{ this.$t('Duplicate.Name') }} :</div>
                    <b-form-input v-model="nameExport" :state="valueNameExportState" ></b-form-input>
                </b-form>
            </b-modal>

            <b-modal id="modal-import-action" :title="this.$t('Import.action.Title')" @ok="this.importAction" size="lg">
                <b-form>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group :label="$t('Path')" label-for="fileselector">
                                <b-form-file 
                                    id="fileselector"
                                    v-model="ptysActionImportFile"
                                    :state="Boolean(ptysActionImportFile)"
                                    :placeholder="$t('project.import.ptysFile')"
                                    :drop-placeholder="$t('project.import.ptysFileDrop')"
                                    accept=".PTYS, .ptys"
                                />
                            </b-form-group>
                           
                        </b-col>
                    </b-row>
                </b-form>
            </b-modal>
            <EquipmentWizard :existingEquipments="equipmentList" :onCreate="onCreateEquipment" :selectedProject="selectedProject" :SetLoadingState="setLoadingState" />
            <ActionWizard :propEquipment="selectedEquipment" :propGroup="selectedGroup" :onCreate="onCreateAction" :propProject="selectedProject" />
            <BackupWizard :propProject="selectedProjectToBackup" :SetLoadingState="setLoadingState" />
            <ProjectConfigurationWizard :propProject="selectedProjectToBackup" :propExistingProjects="existingProjects" :onOk="onProjectWizardConfirm" />
            <ProjectImportWizard :propExistingProjects="existingProjects" :onOk="onImportProjectWizardConfirm" />
        </div>
    </div>
</template>

<script>
import DxTreeView from 'devextreme-vue/tree-view';

import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ProdComActionService from '@/services/prodcom.actions.service.js';
import ProdComDataSourceService from '@/services/prodcom.datasources.service.js';
import ProdComProjectService from '@/services/prodcom.projects.service.js';
import ProdComMonitoringService from '@/services/prodcom.monitoring.service.js';
import PivotGridIcon from '@/components/icons/PivotGridIcon.vue';
import PeriodFilter from '@/components/PeriodFilter.vue';
import ZoomControl from '@/components/ZoomControl.vue';
import ThemeSwitcher from '@/components/ThemeSwitcher.vue';
import FontSizeCtrl from '@/components/FontSizeCtrl.vue';
import TreeView from '@/components/TreeView.vue';
import ContextMenu from '@/components/ContextMenu.vue';
import EquipmentWizard from '@/components/configuration/Equipments/EquipmentWizard.vue';
import ActionWizard from '@/components/configuration/Actions/ActionWizard.vue';
import BackupWizard from '@/components/configuration/Projects/BackupWizard.vue';
import ProjectConfigurationWizard from '@/components/configuration/Projects/ProjectConfigurationWizard.vue';
import ProjectImportWizard from '@/components/configuration/Projects/ProjectImportWizard.vue';
import ToastAlert from '@/utils/ToastAlert';
import { uuid } from 'vue-uuid';
import constants from '@/utils/constants';
import { custom } from 'devextreme/ui/dialog';
import utils from '@/utils';
import DxPopup from 'devextreme/ui/popup';
import DxSelectBox from 'devextreme-vue/select-box';
import DxButton, { DxButtonTypes } from 'devextreme-vue/button';
export default {
    name: 'SideMenu',
    components: {
        DxTreeView,
        PivotGridIcon,
        PeriodFilter,
        ZoomControl,
        FontSizeCtrl,
        ThemeSwitcher,
        TreeView,
        ContextMenu,
        EquipmentWizard,
        ActionWizard,
        BackupWizard,
        ProjectConfigurationWizard,
        ProjectImportWizard,
        DxPopup,
        DxSelectBox,
        DxButton,
    },
    props: {
        dictToRefresh: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            selectItemId: null,
            loading: false,
            visible: true,
            elementToDuplicate: null,
            nameDuplicate: '',
            elementToExport : null,
            nameExport : '',
            opened: false,
            showTimeout: null,
            // isIframe: window.location !== window.parent.location,
            isIframe: false,
            equipments: null,
            // Context menu variables
            // contextMenu: false,
            popupLocationX: 0,
            popupLocationY: 0,
            selectedContextMenuItem: null,
            equipmentWizard: null,
            selectedEquipment: '',
            selectedGroup: '',
            equipmentList: [],
            selectedProject: null,
            selectedProjectToBackup: {},
            existingProjects: [],

            expandedItems: [],
            collapsedItems: [],

            ProjectExportOrigin : '',
            EqExportOrigin : '',
            ActionExportOrigin : '',
            ProjectsModalExport: [],
            valueProjectModalExport : null,
            EqsModalExport : [],
            valueEqModalExport : null,
            GrpActionModalExport : [],
            valueGrpActionModalExport : null,

            ptysActionImportFile: null,
            ProjImportAction: "",
            EqImportAction: "",
            GroupeActionImportAction: "",
        };
    },
    watch: {},
    async mounted() {},
    created() {
        this.$EventBus.$on('refresh-treeview-equipments', this.refreshTreeviewEquipments);
        this.handleContentReady();
    },
    destroyed() {
        // this.$EventBus.$off('equip-set-refresh', this.setRefresh);
    },
    computed: {
        valueNameExportState() {
            return this.nameExport != null && this.nameExport != "";
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn && this.$store.state.auth.user != null && this.$store.state.auth.user.email != '';
        },
        isFirefox() {
            return navigator.userAgent.indexOf('Firefox') != -1;
        },
        menuVisible: {
            get: function () {
                return true;
            },
            set: function (val) {},
        },
        mainMode() {
            return this.$store.state.mainMode;
        },
        LICENSE() {
            return this.$store.state.settings.LICENSE;
        },
        minLevelRole() {
            return this.$store.state.auth.user == null ? 999 : this.$store.state.auth.user.minLevelRole;
        },
        // treeView() {
        //     return this.$refs[treeViewRef].instance;
        // },
        // contextMenu() {
        //     return this.$refs[contextMenuRef].instance;
        // },
    },
    methods: {
        waitForArray( interval = 100) {
            return new Promise(resolve => {
                const checkCondition = async () => {
                // Vérifie si le tableau est initialisé et contient un objet avec itemType = "projects"
                if ( this.$refs.treeViewEquipments?.instance !== null && Array.isArray(this.$refs.treeViewEquipments?.instance.option('items')) && this.$refs.treeViewEquipments?.instance.option('items').some(obj => obj.itemType === "project")) {
                    await new Promise(resolve => setTimeout(resolve, 500));
                    resolve(); // La condition est remplie, on résout la promesse
                } else {
                    await setTimeout(checkCondition, interval); // Réessaie après l'intervalle spécifié
                }
                };

                checkCondition(); // Démarre la vérification
            });
        },
        async handleContentReady(){
            await this.waitForArray();

            let mylocalStorageProjects = localStorage.getItem("ProjectsCollapsed");
            let arrayStorage = JSON.parse(mylocalStorageProjects);
            if(arrayStorage == null)return;
            arrayStorage = arrayStorage.filter((item, index) => arrayStorage.indexOf(item) === index);
            await arrayStorage.forEach(async (el)=>{
                this.$refs.treeViewEquipments.instance.collapseItem(el.id)
            });
        },
        async onClickExportActionToXml(item){
            let arrSplit = item.itemData.parentId.split('¤¤');
            this.ProjectExportOrigin = arrSplit[3];
            this.EqExportOrigin = arrSplit[4];
            this.ActionExportOrigin = item.itemData.text;
            const data = await ProdComActionService.exportActionToXML(this.ProjectExportOrigin,this.EqExportOrigin,this.ActionExportOrigin);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else{
                const byteArray = Uint8Array.from(
                atob(data.ret.content)
                    .split('')
                    .map((char) => char.charCodeAt(0)),
                );
                await utils.SaveFile(byteArray, `[${this.EqExportOrigin}]${this.ActionExportOrigin}.PTYS`, 'data:application/octet-stream');
            }
          
        },
        async setMousePosition(e) {
            this.$store.state.contextMenu.positionX = e.clientX;
            this.$store.state.contextMenu.positionY = e.clientY;
        },
        async setLoadingState(state) {
            this.loading = state;
        },
        async refreshTreeviewEquipments() {
            await this.$refs.treeViewEquipments.instance.option('dataSource', []);
            this.handleContentReady();
        },
        async openContextMenu(item) {
            // Only allow visualizing treeview when not logged in. Don't allow openning the configuration.
            if (!this.loggedIn) return;
            // await this.unselectAllTreeView();
            // this.changeEditCurrent(false);
            this.selectedContextMenuItem = item;
            if (this.selectedContextMenuItem != null) {
                if (item.itemData.itemType === 'equipment') {
                    // Individual equipment
                    this.$store.state.contextMenu.items = [
                        { id: '1', icon: 'fas fa-file-export', text: this.$t('sidebar.contextmenu.export'), onClick: async () => await this.exportEquipment(item), disabled: !this.$store.state.auth.user.permissions.PermissionRead },
                        {
                            id: '2',
                            icon: 'fas fa-copy',
                            text: this.$t('sidebar.contextmenu.duplicate'),
                            onClick: async () => {
                                await this.modalDuplicate(item);
                            },
                            disabled: !this.$store.state.auth.user.permissions.PermissionWrite,
                        },
                        {
                            id: '3',
                            icon: 'fas fa-pen',
                            text: this.$t('sidebar.contextmenu.rename'),
                            onClick: async () => {
                                this.changeEditCurrent(true);
                            },
                            disabled: !this.$store.state.auth.user.permissions.PermissionWrite,
                        },
                        { id: '4', icon: 'fas fa-trash', text: this.$t('sidebar.contextmenu.delete'), onClick: async () => await this.deleteEquipment(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        {
                            id: '5',
                            icon: item.itemData.active ? 'fas fa-ban' : 'fas fa-check',
                            text: item.itemData.active ? this.$t('sidebar.contextmenu.disable') : this.$t('sidebar.contextmenu.enable'),
                            onClick: async () => {
                                await this.disableEquipment(item);
                            },
                            disabled: !this.$store.state.auth.user.permissions.PermissionWrite,
                        },
                        { id: '6', icon: 'fas fa-arrow-up-from-bracket', text: this.$t('sidebar.contextmenu.migrateSimple'), onClick: async () => await this.upgradeManager(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        { id: '7', icon: 'fas fa-arrows-rotate', text: this.$t('sidebar.contextmenu.restart'), onClick: async () => await this.restartEquipment(item), disabled: !this.$store.state.auth.user.permissions.PermissionManageRun },
                    ];
                } else if (item.itemData.itemType === 'action-group') {
                    // Action group
                    this.$store.state.contextMenu.items = [
                        { id: '1', icon: 'fas fa-plus', text: this.$t('sidebar.contextmenu.add'), onClick: async () => await this.addActionGroup(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        { id: '2', icon: 'fas fa-trash', text: this.$t('sidebar.contextmenu.deleteAll'), onClick: async () => await this.deleteAllAction(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        { id: '3', icon: 'fas fa-up-right-from-square', text: this.$t('sidebar.contextmenu.globalView'), onClick: async () => await this.globalActionView(item), disabled: !this.$store.state.auth.user.permissions.PermissionRead },
                    ];
                } else if (item.itemData.itemType === 'action-sub-group') {
                    // Individual group
                    this.$store.state.contextMenu.items = [
                        { id: '1', icon: 'fas fa-plus', text: this.$t('sidebar.contextmenu.add'), onClick: async () => await this.addAction(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        { id: '2', icon: 'fas fa-file-import', text: this.$t('sidebar.contextmenu.import'), onClick: async () => await this.modalImport(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite  },
                        { id: '3', icon: 'fas fa-pen', text: this.$t('sidebar.contextmenu.rename'), onClick: async () =>  this.changeEditCurrent(true),  disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        { id: '4', icon: 'fas fa-trash', text: this.$t('sidebar.contextmenu.delete'), onClick: async () => await this.deleteGroupAction(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                    ];
                } else if (item.itemData.itemType === 'action') {
                    // Individual action
                    this.$store.state.contextMenu.items = [
                        { id: '1', icon: 'fas fa-file-export', text: this.$t('sidebar.contextmenu.export'), onClick: async() => await this.onClickExportActionToXml(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite},
                        { id: '2', icon: 'fas fa-copy', text: this.$t('sidebar.contextmenu.duplicate'), onClick: async() => await this.modalExport(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite},
                        { id: "3", icon : "fas fa-pen", text:this.$t('sidebar.contextmenu.rename'), onClick: async () =>  this.changeEditCurrent(true), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        { id: '5', icon: 'fas fa-trash', text: this.$t('sidebar.contextmenu.delete'), onClick: async () => await this.deleteAction(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                    ];
                } else if (item.itemData.itemType === 'datasource-group') {
                    // Data source list
                    this.$store.state.contextMenu.items = [
                        { id: '1', icon: 'fas fa-plus', text: this.$t('sidebar.contextmenu.add'), onClick: async () => await this.addDataSourceTemplate(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        { id: '2', icon: 'fas fa-trash', text: this.$t('sidebar.contextmenu.deleteAll'), disabled: true },
                    ];
                } else if (item.itemData.itemType === 'datasource') {
                    // Individual data source
                    this.$store.state.contextMenu.items = [
                        { id: '1', icon: 'fas fa-copy', text: this.$t('sidebar.contextmenu.duplicate'), disabled: true },
                        { id: '2', icon: 'fas fa-arrow-up-wide-short', text: this.$t('sidebar.contextmenu.AttachToAllEquipments'), onClick: async () =>  await this.attachDatasourceToAllEquipments(item), disabled:  !this.$store.state.auth.user.permissions.PermissionWrite  },
                        { id: '3', icon: 'fas fa-trash', text: this.$t('sidebar.contextmenu.delete'), onClick: async () => await this.deleteDataSource(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                    ];
                } else if (item.itemData.itemType === 'equipment-group') {
                    // Equipment list
                    this.$store.state.contextMenu.items = [
                        { id: '1', icon: 'fas fa-plus', text: this.$t('sidebar.contextmenu.add'), onClick: async () => await this.popupNewEquipment(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        //{ id: '3', icon: 'fas fa-file-import', text: this.$t('sidebar.contextmenu.import'), disabled: true },
                        //{ id: '2', icon: 'fas fa-file-export', text: this.$t('sidebar.contextmenu.exportAll'), disabled: true },
                        { id: '2', icon: 'fas fa-arrow-up-from-bracket', text: this.$t('sidebar.contextmenu.upgradePMESAll'), onClick: async () => await this.migrateAllEquipements(item), disabled:  !this.$store.state.auth.user.permissions.PermissionWrite  },
                        { id: '4', icon: 'fas fa-trash', text: this.$t('sidebar.contextmenu.deleteAll'), onClick: async () => await this.deleteAllEquipments(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        { id: '5', icon: 'fas fa-check', text: this.$t('sidebar.contextmenu.enableAll'), onClick: async () => await this.enableAllEquipments(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        { id: '6', icon: 'fas fa-ban', text: this.$t('sidebar.contextmenu.disableAll'), onClick: async () => await this.disableAllEquipments(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                    ];
                } else if (item.itemData.itemType === 'project') {
                    // Individual project
                    this.$store.state.contextMenu.items = [
                        { id: '1', icon: 'fas fa-save', text: this.$t('sidebar.contextmenu.backupProject'), onClick: async () => await this.backupProject(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        { id: '2', icon: 'fas fa-check', text: this.$t('sidebar.contextmenu.makeProjectActive'), onClick: async () => await this.setProjectActive(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        {
                            id: '3',
                            icon: 'fas fa-pen',
                            text: this.$t('sidebar.contextmenu.rename'),
                            onClick: async () => {
                                this.changeEditCurrent(true);
                            },
                            disabled: !this.$store.state.auth.user.permissions.PermissionWrite,
                        },
                        { id: '4', icon: 'fas fa-file-export', text: this.$t('sidebar.contextmenu.export'), onClick: async () => { this.exportProject(item) }, disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        { id: '5', icon: 'fas fa-trash', text: this.$t('sidebar.contextmenu.delete'), onClick: async () => await this.deleteProject(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        { id: '6', icon: 'fas fa-cog', text: this.$t('sidebar.contextmenu.projectProperties'), onClick: async () => await this.showProjectProperties(item), disabled: !this.$store.state.auth.user.permissions.PermissionRead },
                    ];
                } else if (item.itemData.itemType === 'project-group') {
                    // Project list
                    this.$store.state.contextMenu.items = [
                        { id: '1', icon: 'fas fa-plus', text: this.$t('sidebar.contextmenu.add'), onClick: async () => await this.addProject(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        { id: '2', icon: 'fas fa-file-import', text: this.$t('sidebar.contextmenu.import'), onClick: async () => await this.importProject(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                        //{ id: '3', icon: 'fas fa-file-export', text: this.$t('sidebar.contextmenu.exportAll'), disabled: true },
                        { id: '4', icon: 'fas fa-trash', text: this.$t('sidebar.contextmenu.deleteAll'), onClick: async () => await this.deleteAllProjects(item), disabled: !this.$store.state.auth.user.permissions.PermissionWrite },
                    ];
                } else if (item.itemData.itemType === 'instance') {
                    // Individual instance
                    if (this.$store.state.auth.user.permissions.PermissionManageRun) {
                        if (this.$store.state.runtimeStatus == 0) {
                            // Runtime stopped
                            this.$store.state.contextMenu.items = [{ id: '1', icon: 'fas fa-play', text: this.$t('sidebar.contextmenu.startRuntime'), onClick: async () => await this.startRuntime() }];
                        } else if (this.$store.state.runtimeStatus == 1) {
                            // Started
                            this.$store.state.contextMenu.items = [
                                { id: '1', icon: 'fas fa-square', text: this.$t('sidebar.contextmenu.stopRuntime'), onClick: async () => await this.stopRuntime() },
                                { id: '2', icon: 'fas fa-arrows-rotate', text: this.$t('sidebar.contextmenu.restartRuntime'), onClick: async () => await this.restartRuntime() },
                            ];
                        } else if (this.$store.state.runtimeStatus == 2) {
                            // Start pending
                            this.$store.state.contextMenu.items = [
                                { id: '1', icon: 'fas fa-square', text: this.$t('sidebar.contextmenu.stopRuntime'), disabled: true },
                                { id: '2', icon: 'fas fa-arrows-rotate', text: this.$t('sidebar.contextmenu.restartRuntime'), disabled: true },
                            ];
                        } else if (this.$store.state.runtimeStatus == 3) {
                            // Stop pending
                            this.$store.state.contextMenu.items = [{ id: '1', icon: 'fas fa-play', text: this.$t('sidebar.contextmenu.startRuntime'), disabled: true }];
                        } else if (this.$store.state.runtimeStatus == 5) {
                            // Runtime unavailable
                            this.$store.state.contextMenu.items = [{ id: '1', icon: 'fas fa-square-exclamation', text: this.$t('sidebar.contextmenu.notAvailable'), disabled: true }];
                        }
                        // Add item at the start of the list
                        this.$store.state.contextMenu.items.unshift({
                            id: '0',
                            icon: 'fas fa-arrows-rotate',
                            text: this.$t('sidebar.contextmenu.refreshConfiguration'),
                            onClick: async () => {
                                this.loading = true;
                                await ProdComProjectService.initialize();
                                await this.refreshTreeviewEquipments();
                                this.loading = false;
                            },
                        });
                    } else {
                        // not authorized to manage runtime
                        this.$store.state.contextMenu.items = [{ id: '1', icon: 'fas fa-square-exclamation', text: this.$t('sidebar.contextmenu.permissionDenied'), disabled: true }];
                    }
                } else {
                    this.$store.state.contextMenu.items = [];
                }
            } else {
                this.$store.state.contextMenu.items = [];
            }

            this.$store.state.contextMenu.display = true;
        },
        async onItemSelected(e, action) {
          
            if(e.itemData.itemType === "project" && (action == "collapsed" || action == "expanded")){
                let arrayStorage = JSON.parse(localStorage.getItem("ProjectsCollapsed"));

                if(arrayStorage === null ){
                    localStorage.setItem("ProjectsCollapsed", JSON.stringify([e.itemData]));
                }else{
                    var res = arrayStorage.filter(item => item.id === e.itemData.id);
                    if(action =="collapsed" ){
                        if(res.length == 0)arrayStorage.push(e.itemData)
                    }else arrayStorage = arrayStorage.filter(item => item.id !== e.itemData.id);
                    arrayStorage = arrayStorage.filter((item, index) => arrayStorage.indexOf(item) === index);

                    localStorage.setItem("ProjectsCollapsed",  JSON.stringify(arrayStorage));
                }
            }
            
            if (e.itemData.editing) return;
            if (action == 'expanded') {
                const index = this.collapsedItems.indexOf(e.itemData.id);
                if (index > -1) {
                    this.collapsedItems.splice(index, 1);
                }
                this.expandedItems.push(e.itemData.id);
            } else if (action == 'collapsed') {
                const index = this.expandedItems.indexOf(e.itemData.id);
                if (index > -1) {
                    this.expandedItems.splice(index, 1);
                }
                this.collapsedItems.push(e.itemData.id);
            }
            if (this.selectItemId == e.itemData.id && e.component.getSelectedNodeKeys().length > 0) return;
            this.selectItemId = e.itemData.id;
            // Only allow visualizing treeview when not logged in. Don't allow openning the configuration.
            if (!this.loggedIn) return;
            this.changeEditCurrent(false);
            if (e.itemData == null) return;
            if (e.itemData.parentId == null) return;

            if (e.itemData.itemType === 'equipment') {
                const selectedEquipment = e.itemData.internalId;
                this.$store.commit('selectEquipmentMonitor', selectedEquipment);
            } else if (e.itemData.itemType == 'equipment-datasource') {
                const actionEquipment = e.node.parent.itemData.internalId;
                const projectId = e.node.parent.parent.parent.itemData.internalId;
                const res = await ProdComDataSourceService.getDataSource(actionEquipment, projectId);
                if (res.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(res.ret, res.retParams), 'warning'));
                else {
                    if (res.ret == null) {
                        res.ret = {
                            Equipment: actionEquipment,
                        };
                    } else {
                        res.ret.Equipment = actionEquipment;
                    }
                    res.ret.ProjectId = projectId;
                    this.$store.commit('selectDataSource', res.ret);
                }
            } else if (e.itemData.itemType == 'datasource') {
                const projectId = e.node.parent.parent.itemData.internalId;
                const res = await ProdComDataSourceService.getDataSourceTemplate(e.itemData.internalId, projectId);
                if (res.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(res.ret, res.retParams), 'warning'));
                else {
                    if (res.ret == null) {
                        res.ret = {
                            Id: e.itemData.internalId,
                            Name: e.itemData.text,
                        };
                    }
                    res.ret.ProjectId = projectId;
                    this.$store.commit('selectDataSourceTemplate', res.ret);
                }
            } else if (e.itemData.itemType == 'action') {
                const actionName = e.itemData.internalId;
                const actionGroup = e.node.parent.itemData.internalId;
                const actionEquipment = e.node.parent.parent.parent.itemData.internalId;
                const projectId = e.node.parent.parent.parent.parent.parent.itemData.internalId;
                const res = await ProdComActionService.getActionDetails(actionName, actionGroup, actionEquipment, projectId);
                if (res.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(res.ret, res.retParams), 'warning'));
                else {
                    res.ret.project = projectId;
                    this.$store.commit('selectAction', res.ret);
                }
            } else if (e.itemData.itemType == 'variables') {
                const actionEquipment = e.node.parent.itemData.internalId;
                const projectId = e.node.parent.parent.parent.itemData.internalId;
                this.$store.commit('selectVariables', { Equipment: actionEquipment, ProjectId: projectId });
            } else if (e.itemData.itemType == 'communication') {
                const actionEquipment = e.node.parent.itemData.internalId;
                const projectId = e.node.parent.parent.parent.itemData.internalId;
                this.$store.commit('selectCommunication', { Equipment: actionEquipment, ProjectId: projectId });
            }
        },
        async getTreeChildrens(parent){
            const parentId = parent ? parent.key : '';
            if (parentId === '') {
                return [
                    {
                        id: 'Instances',
                        internalId: 'Instances',
                        itemType: 'instance-group',
                        icon: 'fas fa-industry-alt fa-fw',
                        iconColor: '#8dc149',
                        parentId: null,
                        text: `${this.$t('Instances')} (1)`,
                        expanded: this.collapsedItems.indexOf('Instances') == -1,
                        hasItems: true,
                        editing: false,
                    },
                    {
                        id: 'Instance¤¤Local',
                        internalId: 'Local',
                        itemType: 'instance',
                        icon: 'fas fa-industry-alt fa-fw',
                        iconColor: '#8dc149',
                        parentId: 'Instances',
                        text: `${this.$t('Local')} (127.0.0.1)`,
                        expanded: this.collapsedItems.indexOf('Instance¤¤Local') == -1,
                        hasItems: true,
                        editing: false,
                    },
                ];
            }

            var result = null;
            switch (parent.itemData.itemType) {
                case 'instance':
                    var projects = await ProdComProjectService.getProjects();
                    if (projects.success == 'n') {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(projects.ret, projects.retParams), 'warning'));
                        return [];
                    }
                    return [
                        {
                            id: `${parent.key}¤¤Projects`,
                            internalId: 'Projects',
                            itemType: 'project-group',
                            icon: 'fas fa-folder fa-fw',
                            iconColor: '#ffe39e',
                            parentId: parent.key,
                            text: `${this.$t('projects')} (${projects.ret.length})`,
                            expanded: this.collapsedItems.indexOf(`${parent.key}¤¤Projects`) == -1,
                            hasItems: true,
                            editing: false,
                        },
                    ].concat(
                        projects.ret.map((project) => {
                            return {
                                id: `${parent.key}¤¤Projects¤¤${project.Id}`,
                                internalId: project.Id,
                                itemType: 'project',
                                icon: project.Active ? 'fas fa-industry fa-fw' : 'fal fa-industry fa-fw',
                                iconColor: '#1ca8dd',
                                parentId: `${parent.key}¤¤Projects`,
                                text: project.Name,
                                expanded: this.collapsedItems.indexOf(`${parent.key}¤¤${project.Id}`) == -1,
                                hasItems: true,
                                editing: false,
                            };
                        }),
                    );
                case 'project':
                    var equipments = await ProdComEquipmentService.getAllEquipments(parent.itemData.internalId, false);
                    if (equipments.success == 'n') {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(equipments.ret, equipments.retParams), 'warning'));
                        return [];
                    }
                    var dataSources = await ProdComDataSourceService.getDataSourceList(parent.itemData.internalId);
                    if (dataSources.success == 'n') {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(dataSources.ret, dataSources.retParams), 'warning'));
                        return [];
                    }
                    return [
                        {
                            id: `${parent.key}¤¤Equipments`,
                            internalId: 'Equipments',
                            itemType: 'equipment-group',
                            icon: '',
                            parentId: parent.key,
                            text: `${this.$t('Devices')} (${equipments.ret.length})`,
                            expanded: equipments.ret.length > 0 && this.collapsedItems.indexOf(`${parent.key}¤¤Equipments`) == -1,
                            hasItems: true,
                            editing: false,
                        },
                        {
                            id: `${parent.key}¤¤DataSources`,
                            internalId: 'DataSources',
                            itemType: 'datasource-group',
                            icon: '',
                            parentId: parent.key,
                            text: `${this.$t('Data Sources')} (${dataSources.ret.length})`,
                            expanded: this.collapsedItems.indexOf(`${parent.key}¤¤DataSources`) == -1,
                            hasItems: true,
                            editing: false,
                        },
                    ].concat(
                        equipments.ret.map((equipment) => {
                            return {
                                id: `${parent.key}¤¤${equipment.Name}`,
                                internalId: equipment.Name,
                                itemType: 'equipment',
                                icon: equipment.Active ? 'fas fa-container-storage fa-fw' : 'fal fa-container-storage fa-fw',
                                iconColor: equipment.Active ? '#8dc149' : '#dadada',
                                parentId: `${parent.key}¤¤Equipments`,
                                text: equipment.Name,
                                expanded: this.expandedItems.indexOf(`${parent.key}¤¤${equipment.Name}`) != -1,
                                hasItems: true,
                                varsCount: equipment.VariableCount,
                                ProdComVarsCount: equipment.ProdComVariableCount,
                                actionsCount: equipment.ActionCount,
                                datasourceName: equipment.DataSourceName,
                                active: equipment.Active,
                                editing: false,
                            };
                        }),
                        dataSources.ret.map((dataSource) => {
                            return {
                                id: `${parent.key}¤¤${dataSource.Id}`,
                                internalId: dataSource.Id,
                                itemType: 'datasource',
                                icon: 'fas fa-database fa-fw',
                                iconColor: '#f55385',
                                parentId: `${parent.key}¤¤DataSources`,
                                text: dataSource.Name,
                                expanded: false,
                                hasItems: false,
                                editing: false,
                            };
                        }),
                    );
                case 'equipment':
                    return [
                        {
                            id: `${parent.key}¤¤Communication`,
                            internalId: 'Communication',
                            itemType: 'communication',
                            icon: 'far fa-ethernet fa-fw',
                            iconColor: '#a1887f',
                            parentId: parent.key,
                            text: `${this.$t('Communication')}`,
                            expanded: false,
                            hasItems: false,
                            editing: false,
                        },
                        {
                            id: `${parent.key}¤¤Variables`,
                            internalId: 'Variables',
                            itemType: 'variables',
                            icon: 'fas fa-chart-line fa-fw',
                            iconColor: '#5492dc',
                            parentId: parent.key,
                            text: `${this.$t('Vars')} (${parent.itemData.varsCount}/${parent.itemData.varsCount + parent.itemData.ProdComVarsCount})`,
                            expanded: false,
                            hasItems: false,
                            editing: false,
                        },
                        {
                            id: `${parent.key}DataSource`,
                            internalId: 'DataSource',
                            itemType: 'equipment-datasource',
                            icon: 'fas fa-database fa-fw',
                            iconColor: '#f55385',
                            parentId: parent.key,
                            text: parent.itemData.datasourceName ? parent.itemData.datasourceName : `${this.$t('Data Source')}`,
                            expanded: false,
                            hasItems: false,
                            editing: false,
                        },
                        {
                            id: `${parent.key}¤¤Actions`,
                            internalId: 'Actions',
                            itemType: 'action-group',
                            icon: '',
                            iconColor: '#5492dc',
                            parentId: parent.key,
                            text: `${this.$t('Actions')} (${parent.itemData.actionsCount})`,
                            expanded: parent.itemData.actionsCount > 0 && this.expandedItems.indexOf(`${parent.key}¤¤Actions`) != -1,
                            hasItems: true,
                            editing: false,
                        },
                    ];
                case 'action-group':
                    var actions = await ProdComActionService.getActions(parent.parent.itemData.internalId, parent.parent.parent.parent.itemData.internalId);
                    if (actions.success == 'n') {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(actions.ret, actions.retParams), 'warning'));
                        return [];
                    }
                    var actionsList = [];
                    var actionsGroups = [];
                    actions.ret.List.forEach((action) => {
                        var actionGroup = actionsGroups.find((group) => group.internalId == action.Group);
                        if (!actionGroup) {
                            actionsGroups.push({
                                id: `${parent.key}¤¤${action.Group}`,
                                internalId: action.Group,
                                itemType: 'action-sub-group',
                                icon: '',
                                iconColor: '#5492dc',
                                parentId: parent.key,
                                text: `${action.Group} (1)`,
                                expanded: actionsGroups.length == 0 && this.collapsedItems.indexOf(`${parent.key}¤¤${action.Group}`) == -1,
                                hasItems: true,
                                actionsCount: 1,
                                editing: false,
                            });
                        } else {
                            actionGroup.actionsCount++;
                            actionGroup.text = `${actionGroup.internalId} (${actionGroup.actionsCount})`;
                        }
                        actionsList.push({
                            id: `${parent.key}¤¤${action.Group}¤¤${action.Name}`,
                            internalId: action.Name,
                            itemType: 'action',
                            icon: 'fas fa-arrows-rotate',
                            iconColor: '#8dc149',
                            parentId: `${parent.key}¤¤${action.Group}`,
                            text: action.Name,
                            expanded: false,
                            hasItems: false,
                            editing: false,
                        });
                    });
                    return actionsGroups.concat(actionsList);
                default:
                    return [];
            }
        }
        ,
        async createChildren(parent) {
            return await new Promise(async (resolve) => {
                // Résoudre la promesse après avoir retourné les enfants
                resolve(await this.getTreeChildrens(parent));
            }).then(async (children) => {
                // Exécuter une logique après la résolution
                return children;
            });

           
        },
        // async clearRefresh() {
        //     if (this.dictToRefresh != {}) {
        //         this.dictToRefresh = {}
        //         // await this.refreshTreeviewEquipments();
        //     }
        // },
        // async setRefresh(data) {
        //     if (data.toRefresh) {
        //         if (this.dictToRefresh[data.ProjectId] == null) {
        //             this.dictToRefresh[data.ProjectId] = [];
        //         }
        //         this.dictToRefresh[data.ProjectId][data.Name] = true;
        //     } else {
        //         delete this.dictToRefresh[data.ProjectId][data.Name]
        //     }
        //     // await this.refreshTreeviewEquipments();
        // },
        cancelDashboardImport() {
            this.loading = false;
            this.$bvModal.hide('bv-modal-pannel-fix');
        },

        async selectItem(data) {
            // Only allow visualizing treeview when not logged in. Don't allow openning the configuration.
            if (!this.loggedIn) return;
            this.changeEditCurrent(false);
            if (data.length != 1) return;
            data = data[0];
            if (data == null) return;
            if (data.parent == null) return;
            if (data?.parent?.value === 'Equipements') {
                const selectedEquipment = data?.value;
                this.$store.commit('selectEquipmentMonitor', selectedEquipment);
            }
            if (data.value == 'datasource' && data?.parent?.parent?.value == 'Equipements') {
                const actionEquipment = data.parent.text;
                const projectId = data?.parent?.parent?.parent.id;
                const res = await ProdComDataSourceService.getDataSource(actionEquipment, projectId);
                if (res.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(res.ret, res.retParams), 'warning'));
                else {
                    if (res.ret == null) {
                        res.ret = {
                            Equipment: actionEquipment,
                        };
                    } else {
                        res.ret.Equipment = actionEquipment;
                    }
                    res.ret.ProjectId = projectId;
                    this.$store.commit('selectDataSource', res.ret);
                }
            } else if (data.parent.value == 'Data Sources') {
                const projectId = data?.parent?.parent.id;
                const res = await ProdComDataSourceService.getDataSourceTemplate(data.id, projectId);
                if (res.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(res.ret, res.retParams), 'warning'));
                else {
                    if (res.ret == null) {
                        res.ret = {
                            Id: data.id,
                            Name: data.text,
                        };
                    }
                    res.ret.ProjectId = projectId;
                    this.$store.commit('selectDataSourceTemplate', res.ret);
                }
            } else if (data?.parent?.parent?.parent != null && data?.parent?.parent?.text?.startsWith('Actions')) {
                const actionName = data.text;
                const actionGroup = data.parent.value;
                const actionEquipment = data.parent.parent.parent.text;
                const projectId = data.parent.parent.parent.parent.parent.id;
                const res = await ProdComActionService.getActionDetails(actionName, actionGroup, actionEquipment, projectId);
                if (res.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(res.ret, res.retParams), 'warning'));
                else {
                    res.ret.project = projectId;
                    this.$store.commit('selectAction', res.ret);
                }
            } else if (data.value == 'variables' && data?.parent?.parent?.value == 'Equipements') {
                const actionEquipment = data.parent.text;
                const projectId = data?.parent?.parent?.parent.id;
                this.$store.commit('selectVariables', { Equipment: actionEquipment, ProjectId: projectId });
            } else if (data.text == this.$t('Communication') && data?.parent?.parent?.value == 'Equipements') {
                const actionEquipment = data.parent.text;
                const projectId = data?.parent?.parent?.parent.id;
                this.$store.commit('selectCommunication', { Equipment: actionEquipment, ProjectId: projectId });
            }
        },
        async onItemEditValidated(item) {
            // Only allow visualizing treeview when not logged in. Don't allow openning the configuration.
            if (!this.loggedIn) return;
            if (item.itemType == 'equipment') {
                if (item.internalId == item.text) {
                    item.editing = false;
                    return;
                }
                this.loading = true;
                const parentsId = item.parentId.split('¤¤');
                var projectId = parentsId[parentsId.length - 2];
                const _internalId = item.internalId;
                const result = await ProdComEquipmentService.setEquipementName(item.text, _internalId, projectId);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
                else {
                    // Remove the Monaco settings for the project
                    this.$store.commit('removeMonacoProjectSettings', projectId);
                    await this.$store.commit('closeTabsForEquipment', { Name: _internalId, ProjectId: projectId });
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'info'));
                    item.editing = false;
                    item.internalId = item.text;
                }
                this.loading = false;
            } else if (item.itemType == 'project') {
                this.loading = true;
                if (item.internalId == '0') {
                    // Adding a new project
                    const data = await ProdComProjectService.createProject(item.text);
                    if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    else {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('project.created'), 'info'));
                    }
                    await this.refreshTreeviewEquipments();
                } else {
                    // Renaming an existing project
                    const data = await ProdComProjectService.rename(item.internalId, item.text);
                    if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    else {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('project.renamed'), 'info'));
                    }
                    await this.refreshTreeviewEquipments();
                }
                this.loading = false;
            } else if (item.itemType == 'datasource') {
                item.editing = false;
                this.selectItemId = item.id;
                const parentsId = item.parentId.split('¤¤');
                var projectId = parentsId[parentsId.length - 2];
                this.onItemSelected(
                    {
                        component: this.$refs.treeViewEquipments.instance,
                        itemData: {
                            itemType: 'datasource',
                            parentId: item.parentId,
                            internalId: item.internalId,
                            id: item.id,
                            text: item.text,
                        },
                        node: {
                            parent: {
                                parent: {
                                    itemData: {
                                        internalId: projectId,
                                    },
                                },
                            },
                        },
                    },
                    'click',
                );
            } else if (item.itemType == 'action'){
                let newValue = item.text;
                let oldValue = item.internalId;
                if(newValue !== oldValue){
                    let arrSplit = item.parentId.split('¤¤');
                    let eq = arrSplit[4];
                    let proj = arrSplit[3];
                    const data = await ProdComActionService.renameAction(oldValue,newValue,eq,proj);
                    if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    else {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('action.rename.success'), 'success'));
                    }
                    await this.refreshTreeviewEquipments();
                }
                item.editing = false;
            }else if (item.itemType == 'action-sub-group') {
                let newValue = item.text;
                let oldValue = item.internalId;
                item.internalId = item.text;
                item.editing = false;
                  if(newValue !== oldValue){
                    let arrSplit = item.parentId.split('¤¤');
                    let eq = arrSplit[4];
                    let proj = arrSplit[3];
                    const data = await ProdComActionService.renameGroupeAction(proj,eq, newValue,oldValue);
                    if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    else {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('group-action.rename.success'), 'success'));
                    }
                    await this.refreshTreeviewEquipments();
                }
            } else {
                item.editing = false;
            }
        },
        lockSideMenu() {
            //this.opened = !this.opened;
        },
        async unselectAllTreeView() {
            await this.$refs.treeViewEquipments.instance.unselectAll();
        },
        async showModal(modalName) {
            this.$bvModal.show(modalName);
        },
        async changeEditCurrent(value) {
            var scroll = document.getElementById('sidebar-menu-content').scrollTop;
            if (this.selectedContextMenuItem != null && this.selectedContextMenuItem.itemData.editing != value) {
                this.selectedContextMenuItem.itemData.editing = value;
                console.log(this.selectedContextMenuItem.itemData);
                if(this.selectedContextMenuItem.itemData.itemType == "action-sub-group")this.selectedContextMenuItem.itemData.text = this.selectedContextMenuItem.itemData.internalId;
                await this.$refs.treeViewEquipments.instance.repaint();
                document.getElementById('sidebar-menu-content').scrollTop = scroll;
            }
        },
        async onItemEditCanceled(item) {
            var scroll = document.getElementById('sidebar-menu-content').scrollTop;
            if (item.editing && item.itemType != 'equipment') {
                // New item, remove it
                var items = this.$refs.treeViewEquipments.instance.option('items');
                items = items.filter((item) => item.editing != true);
                this.$refs.treeViewEquipments.instance.option('items', items);

                var parentItemsCount = items.filter((it) => it.parentId == item.parentId).length;
                var parentItem = items.find((it) => it.id == item.parentId);
                if (item.itemType == 'datasource') parentItem.text = `${this.$t('Data Sources')} (${parentItemsCount})`;
                else if (item.itemType == 'project') parentItem.text = `${this.$t('projects')} (${parentItemsCount})`;
                else if (item.itemType == 'action-sub-group') parentItem.text = `${this.$t('Actions')} (${parentItemsCount})`;
            } else {
                item.editing = false;
                await this.$refs.treeViewEquipments.instance.repaint();
            }
            document.getElementById('sidebar-menu-content').scrollTop = scroll;
        },
        async onCreateEquipment() {
            // Remove the Monaco settings for the project
            this.$store.commit('removeMonacoProjectSettings', this.selectedProject);

            // Refresh the equipment list when a new equipment has been created
            await this.refreshTreeviewEquipments();
            this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.created'), 'info'));
        },
        async onCreateAction() {
            // Refresh the equipment list when a new equipment has been created
            await this.refreshTreeviewEquipments();
            this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('action.created'), 'info'));
        },
        async showProjectProperties(item) {
            if (!this.$store.state.auth.user.permissions.PermissionRead) return;
            this.selectedProjectToBackup = item;
            this.showModal('projectWizard');
        },
        async startRuntime() {
            if (!this.$store.state.auth.user.permissions.PermissionManageRun) return;
            const result = await ProdComMonitoringService.startRuntime();
            if (result.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            } else {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('runtime.started'), 'info'));
                // this.clearRefresh();
            }
        },
        async stopRuntime() {
            if (!this.$store.state.auth.user.permissions.PermissionManageRun) return;
            const result = await ProdComMonitoringService.stopRuntime();
            if (result.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            } else {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('runtime.stopped'), 'info'));
                // this.clearRefresh();
            }
        },
        async restartRuntime() {
            if (!this.$store.state.auth.user.permissions.PermissionManageRun) return;
            const result = await ProdComMonitoringService.restartRuntime();
            if (result.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            } else {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('runtime.restarted'), 'info'));
                // this.clearRefresh();
            }
        },

        /**
         * Imports a project exported as a .PTYS file
         */
        async importProject(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            this.showModal('projectImportWizard');
        },

        /**
         * Shows the popup for backuping the selected project.
         */
        async backupProject(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            this.selectedProjectToBackup = item;
            this.showModal('backup');
        },
        /**
         * Adds a new project to the list
         */
        async addProject(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            var items = this.$refs.treeViewEquipments.instance.option('items');
            if (items.findIndex((item) => item.id == '0') != -1) return;

            var newProjectName = this.$t('project');
            var i = 1;
            while (items.findIndex((it) => it.itemType == 'project' && it.text === `${newProjectName} ${i}`) != -1) {
                i++;
            }
            newProjectName = `${newProjectName} ${i}`;

            var parentItemsCount = items.filter((it) => it.itemType == 'project' && it.parentId == item.itemData.id).length;

            var newProject = {
                editing: true,
                expanded: false,
                hasItems: false,
                icon: '',
                iconColor: '',
                id: '0',
                internalId: '0',
                itemType: 'project',
                parentId: item.itemData.id,
                text: newProjectName,
            };
            items.push(newProject);
            this.$refs.treeViewEquipments.instance.option('items', items);
            var parentItem = items.find((it) => it.itemType == 'project-group' && it.id == item.itemData.id);
            parentItem.text = `${this.$t('projects')} (${parentItemsCount + 1})`;
        },
        /**
         * Sets the selected project active for runtime. Deactivates every other projects.
         */
        async setProjectActive(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            this.loading = true;
            const data = await ProdComProjectService.setActive(item.itemData.internalId);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                await this.refreshTreeviewEquipments();
            }
            this.loading = false;
        },
        /**
         * Deletes the selected project if the user confirms it.
         */
        async deleteProject(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            let dlg = custom({
                title: this.$t('confirmDeleteAllTitle'),
                messageHtml: `<div style="width: 450px;">${this.$t('confirmDeleteAll')}</div>`, // Make sure the title is fully displayed
                buttons: [
                    {
                        text: this.$t('Ok'),
                        onClick: function () {
                            return true;
                        },
                    },
                    {
                        text: this.$t('Cancel'),
                        onClick: function () {
                            return false;
                        },
                    },
                ],
            });

            var resultDialog = await dlg.show();
            if (resultDialog) {
                this.loading = true;
                const data = await ProdComProjectService.deleteProject(item.itemData.internalId);
                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    // Remove the Monaco settings for the project
                    this.$store.commit('removeMonacoProjectSettings', item.itemData.internalId);

                    // Close any corresponding tabs
                    this.$store.commit('closeTabsForProject', item.itemData.internalId);
                    await this.refreshTreeviewEquipments();
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('project.deleted'), 'info'));
                }
                this.loading = false;
            }
        },
        /**
         * Deletes all the project in the current instance.
         */
        async deleteAllProjects(item) {
            let dlg = custom({
                title: this.$t('confirmDeleteAllTitle'),
                messageHtml: `<div style="width: 450px;">${this.$t('confirmDeleteAll')}</div>`, // Make sure the title is fully displayed
                buttons: [
                    {
                        text: this.$t('Ok'),
                        onClick: function () {
                            return true;
                        },
                    },
                    {
                        text: this.$t('Cancel'),
                        onClick: function () {
                            return false;
                        },
                    },
                ],
            });

            var resultDialog = await dlg.show();
            if (resultDialog) {
                if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
                this.loading = true;
                const data = await ProdComProjectService.deleteAllProjects();
                if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                else {
                    // Remove the Monaco settings
                    this.$store.commit('removeMonacoSettings');

                    // Close all tabs
                    this.$store.commit('closeAllTabs');
                    // Project has been deleted, we can refresh the frontend and backend
                    await this.refreshTreeviewEquipments();
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('project.deletedAll'), 'info'));
                }
                this.loading = false;
            }
        },
        /**
         * Shows the "New Equipment" popup
         */
        async popupNewEquipment(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            this.selectedProject = item.node.parent.itemData.internalId;
            this.equipmentList = item.node.items;
            // show modal on next tick to force mutation of the property equipmentList
            this.$nextTick(() => {
                this.showModal('newEquipment');
            });
        },
        /**
         * Deletes all the equipments of the selected project.
         */
        async deleteAllEquipments(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            let dlg = custom({
                title: this.$t('confirmDeleteAllTitle'),
                messageHtml: `<div style="width: 450px;">${this.$t('confirmDeleteAll')}</div>`, // Make sure the title is fully displayed
                buttons: [
                    {
                        text: this.$t('Ok'),
                        onClick: function () {
                            return true;
                        },
                    },
                    {
                        text: this.$t('Cancel'),
                        onClick: function () {
                            return false;
                        },
                    },
                ],
            });
            var resultDialog = await dlg.show();
            if (resultDialog) {
                this.loading = true;
                const projectId = item.node.parent.itemData.internalId;
                const result = await ProdComEquipmentService.deleteAllEquipments(projectId);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                else {
                    // Remove the Monaco settings for the project
                    this.$store.commit('removeMonacoProjectSettings', projectId);

                    // Close any corresponding tab
                    this.$store.commit('closeTabsForProject', projectId);
                    await this.refreshTreeviewEquipments();
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.deletedAll'), 'info'));
                }
                this.loading = false;
            }
        },

        /**
         * Activate all the equipments of the selected project.
         */
        async enableAllEquipments(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            this.loading = true;
            const projectId = item.node.parent.itemData.internalId;
            const result = await ProdComEquipmentService.enableAllEquipments(projectId);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else {
                // all equipments have been deleted, we can refresh the frontend and backend
                await this.refreshTreeviewEquipments();
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.enabledAll'), 'info'));
            }
            this.loading = false;
        },

        /**
         * Deactivate all the equipments of the selected project.
         */
        async disableAllEquipments(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            this.loading = true;
            const projectId = item.node.parent.itemData.internalId;
            const result = await ProdComEquipmentService.disableAllEquipments(projectId);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else {
                // all equipments have been deleted, we can refresh the frontend and backend
                await this.refreshTreeviewEquipments();
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.disabledAll'), 'info'));
            }
            this.loading = false;
        },

        /**
         * Upgrade the selected equipment to the latest version of the Manager template
         */
        async upgradeManager(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            let dlg = custom({
                title: this.$t('equipment.prodManager.confirmUpgradeTitle'),
                messageHtml: `<div style="width: 450px;">${this.$t('equipment.prodManager.confirmUpgrade')}</div>`, // Make sure the title is fully displayed
                buttons: [
                    {
                        text: this.$t('Ok'),
                        onClick: function () {
                            return true;
                        },
                    },
                    {
                        text: this.$t('Cancel'),
                        onClick: function () {
                            return false;
                        },
                    },
                ],
            });
            var resultDialog = await dlg.show();
            if (resultDialog) {
                this.loading = true;
                const equipName = item.itemData.internalId;
                const projectId = item.node.parent.parent.itemData.internalId;
                const result = await ProdComEquipmentService.upgradeManager(equipName, projectId);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                else {
                    // Close any corresponding tab
                    this.$store.commit('closeTabsForEquipment', { Name: equipName, ProjectId: projectId });
                    // Equipment has been deleted, we can refresh the frontend and backend
                    await this.refreshTreeviewEquipments();
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.migrated'), 'info'));
                }
                this.loading = false;
            }
        },
        async modalDuplicate(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            this.elementToDuplicate = item;
            // Compute the default name for the duplicated element and add -index at the end if the name already exists
            var equipments = item.node.parent.items;
            var i = 1;
            var name = item.itemData.text;
            while (equipments.findIndex((it) => it.itemData.text === `${name} - ${i}`) != -1) {
                i++;
            }
            this.nameDuplicate = `${name} - ${i}`;
            // this.nameDuplicate = this.elementToDuplicate.itemData.text + ' Copy';
            this.$bvModal.show('modal-dupli');
        },
        async modalExport(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            this.elementToExport = item;
            // Compute the default name for the duplicated element and add -index at the end if the name already exists
            var equipments = item.node.parent.items;
            var name = item.itemData.text;
            this.nameExport = `${name}`;
            let arrSplit = item.itemData.parentId.split('¤¤');
            let eq = arrSplit[4];
            let proj = arrSplit[3];
            let groupAction = arrSplit[6]
            this.ProjectExportOrigin = proj;
            this.EqExportOrigin = eq;
            this.ActionExportOrigin = name;
            let resProjects = await ProdComProjectService.getProjects();
            //Récupérer les projets et set le defaut
            this.ProjectsModalExport = resProjects.ret.map(obj => {return {
                name : obj.Name,
                id : obj.Id
            }});
            this.valueProjectModalExport = resProjects.ret.find(obj => obj.Id == proj).Id;
            
            //Récupérer les equipements du projet et set le defaut
            let resEquipements = await ProdComEquipmentService.getAllEquipments(proj,false);
            this.EqsModalExport = resEquipements.ret.map(obj => obj.Name).sort();
            this.valueEqModalExport = eq;

            //Récupérer les groupes d'un équipement  et set le defaut
            let resGroups = await ProdComActionService.getGroups(proj,eq);
            this.GrpActionModalExport = resGroups.ret.sort();
            this.valueGrpActionModalExport = groupAction;

            this.$bvModal.show('modal-export');
        },
        async OnValueProjectChangedModalExport(e){
            let resEquipements = await ProdComEquipmentService.getAllEquipments(e.value,false);
            if(resEquipements.ret == null || resEquipements.ret.length == 0 ){
                this.EqsModalExport = [];
                this.GrpActionModalExport = [];
                this.valueEqModalExport = null;
                this.valueGrpActionModalExport = null;
            }else{
                this.EqsModalExport = resEquipements.ret.map(obj => obj.Name).sort();
                this.valueEqModalExport = this.EqsModalExport[0];
            }
            
        },
        async OnValueEqChangedModalExport(e){
            if(this.valueEqModalExport == null)return;
            let resGroups = await ProdComActionService.getGroups(this.valueProjectModalExport,e.value);
            if(resGroups.ret == null || resGroups.ret.length == 0 ){
                this.GrpActionModalExport = [];
                this.valueGrpActionModalExport = null;

            }else{
                this.GrpActionModalExport = resGroups.ret.sort();
                this.valueGrpActionModalExport = this.GrpActionModalExport[0];
            }
        },
        async OnValueGrpActionChangedModalExport(e){
        },
        
        async export(){
            this.loading = true;
            if(this.valueGrpActionModalExport == null || this.valueGrpActionModalExport == "")this.valueGrpActionModalExport= "Groupe 1";
            let result = await ProdComActionService.exportAction(
                this.ProjectExportOrigin,
                this.valueProjectModalExport,
                this.EqExportOrigin, 
                this.valueEqModalExport,
                this.valueGrpActionModalExport,
                this.ActionExportOrigin,
                this.nameExport);
            if (result == null || result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
            else {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'success'));
                this.refreshTreeviewEquipments();
            }
            this.loading = false;
        },
        async importAction(){
            this.loading = true;
            // Créez une instance de FileReader
            const reader = new FileReader();
            // Définissez une fonction pour gérer le chargement du fichier
            reader.onload =async (event) => {
                // Le contenu du fichier est disponible dans event.target.result
                const fileContent = event.target.result;
                let result = await ProdComActionService.importAction(
                    this.ProjImportAction,
                    this.EqImportAction,
                    this.GroupeActionImportAction,
                    fileContent
                );
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                else {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('action.imported'), 'success'));
                }
                 this.refreshTreeviewEquipments();
                
                this.loading = false;
            };
            reader.readAsText(this.ptysActionImportFile);
        },
        async modalImport(item){
            let arrSplit = item.itemData.parentId.split('¤¤');
            this.EqImportAction = arrSplit[4];
            this.ProjImportAction = arrSplit[3];
            this.GroupeActionImportAction = item.itemData.internalId;
            this.$bvModal.show('modal-import-action');
        },
        async duplication() {
            let result = null;
            this.loading = true;
            let projectId = null;
            if (this.elementToDuplicate.itemData.itemType == 'equipment') {
                projectId = this.elementToDuplicate.node.parent.parent.itemData.internalId;
                result = await ProdComEquipmentService.duplicateEquipement(this.nameDuplicate, this.elementToDuplicate.itemData.internalId, projectId);
            } else {
                const equipmentName = this.elementToDuplicate.node.parent.parent.parent.itemData.internalId;
                projectId = this.elementToDuplicate.node.parent.parent.parent.parent.parent.itemData.internalId;
                result = await ProdComActionService.duplicateAction(this.elementToDuplicate.itemData.internalId, this.nameDuplicate, equipmentName, projectId);
            }
            if (result == null || result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
            else {
                // Remove the Monaco settings for the project
                this.$store.commit('removeMonacoProjectSettings', projectId);
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'info'));
                this.refreshTreeviewEquipments();
            }
            this.loading = false;
        },
        /**
         * Deletes the equipment referenced by its node
         */
        async deleteEquipment(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            let dlg = custom({
                title: this.$t('confirmDeleteAllTitle'),
                messageHtml: `<div style="width: 450px;">${this.$t('confirmDelete')}</div>`, // Make sure the title is fully displayed
                buttons: [
                    {
                        text: this.$t('Ok'),
                        onClick: function () {
                            return true;
                        },
                    },
                    {
                        text: this.$t('Cancel'),
                        onClick: function () {
                            return false;
                        },
                    },
                ],
            });
            var resultDialog = await dlg.show();
            if (resultDialog) {
                this.loading = true;
                const equipName = item.itemData.internalId;
                const projectId = item.node.parent.parent.itemData.internalId;
                const result = await ProdComEquipmentService.deleteEquipment(equipName, projectId);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                else {
                    // Remove the Monaco settings for the project
                    this.$store.commit('removeMonacoProjectSettings', projectId);
                    // Close any corresponding tab
                    this.$store.commit('closeTabsForEquipment', { Name: equipName, ProjectId: projectId });
                    // Equipment has been deleted, we can refresh the frontend and backend
                    await this.refreshTreeviewEquipments();
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.deleted'), 'info'));
                }
                this.loading = false;
            }
        },
        /**
         * Exports the equipment referenced by its node
         */
        async exportEquipment(item) {
            if (!this.$store.state.auth.user.permissions.PermissionRead) return;
            this.loading = true;
            const equipName = item.itemData.internalId;
            const projectId = item.node.parent.parent.itemData.internalId;
            const result = await ProdComEquipmentService.exportEquipment(equipName, projectId);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else {
                //await utils.SaveFile(result.ret.key, equipName, 'text/plain;charset=utf-8');
                /*const byteArray = Uint8Array.from(
                    atob(result.ret.encryptedContent)
                        .split('')
                        .map((char) => char.charCodeAt(0)),
                );*/
                await utils.SaveFile(result.ret, `${equipName}.PTYS`, 'data:application/octet-stream');
            }
            this.loading = false;
        },
        /**
         * Exports the equipment referenced by its node
         */
        async exportProject(item) {
            if (!this.$store.state.auth.user.permissions.PermissionRead) return;
            this.loading = true;
            const projectId = item.itemData.internalId;
            const projectName = item.itemData.text;
            const result = await ProdComProjectService.export(projectId);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else {
                //await utils.SaveFile(result.ret.key, projectName, "text/plain;charset=utf-8");
                //const byteArray = Uint8Array.from(atob(result.ret.encryptedContent).split('').map(char => char.charCodeAt(0)))
                
                for (let i = 0; i < result.ret.length; i++) {
                    await utils.SaveFile(result.ret[i], `${projectName}-`+ i + `.PTYS`, "data:application/octet-stream");
                }
                
            }
            this.loading = false;
        },
        /**
         * Disables / enables the equipment referenced by its node
         */
        async disableEquipment(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            this.loading = true;
            const equipName = item.itemData.internalId;
            const projectId = item.node.parent.parent.itemData.internalId;
            const result = await ProdComEquipmentService.activateEquipment(projectId, equipName, !item.itemData.active);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else {
                await this.refreshTreeviewEquipments();
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(item.itemData.active ? 'equipment.disabled' : 'equipment.enabled'), 'info'));
            }
            this.loading = false;
        },
        async attachDatasourceToAllEquipments(item){
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
                this.loading = true;
                const projectId = item.node.parent.parent.itemData.internalId;
                const dbId = item.itemData.internalId;
                const result = await ProdComDataSourceService.setDataSourceToAllEq(projectId, dbId);
                
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
                else {
                    await this.refreshTreeviewEquipments();
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.all.attachToDatabase'), 'info'));
                }
                this.loading = false;
        },
        async migrateAllEquipements(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            let dlg = custom({
                title: this.$t('equipment.prodManager.confirmUpgradeTitle'),
                messageHtml: `<div style="width: 450px;">${this.$t('equipment.prodManager.confirmUpgradeAll')}</div>`, // Make sure the title is fully displayed
                buttons: [
                    { text: this.$t('Ok'), onClick: function () { return true } },
                    { text: this.$t('Cancel'), onClick: function () { return false } }
                ]
            });
            var resultDialog = await dlg.show();
            if (resultDialog) {
                this.loading = true;
                const projectId = item.node.parent.itemData.internalId;
                const result = await ProdComProjectService.upgradeAllManager(projectId);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                else {
                    await this.refreshTreeviewEquipments();
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.all.migrated'), 'info'));
                }
                this.loading = false;
            }
        },
        /**
         * Restarts the equipment referenced by its node
         */
        async restartEquipment(item) {
            if (!this.$store.state.auth.user.permissions.PermissionManageRun) return;
            if (this.$store.state.runtimeStatus != 1) {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('runtime.status.runtimeUnavailable'), 'warning'));
                return;
            }
            this.loading = true;
            const equipName = item.itemData.internalId;
            const result = await ProdComMonitoringService.restartEquipment(equipName);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.restarted'), 'info'));
                await this.refreshTreeviewEquipments();
            }
            this.loading = false;
        },
        /**
         * Adds a new action group (no effect on server as groups are only referenced in action configuration.)
         */
        async addActionGroup(item) {
            await item.component.expandItem(item.itemData.id);
            this.$nextTick(() => {
                if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
                var items = this.$refs.treeViewEquipments.instance.option('items');
                if (items.findIndex((item) => item.id == '0') != -1) return;

                var newGroupName = this.$t('Group');
                var i = 1;
                while (items.findIndex((it) => it.itemType == 'action-sub-group' && it.parentId == item.itemData.id && it.internalId === `${newGroupName} ${i}`) != -1) {
                    i++;
                }
                newGroupName = `${newGroupName} ${i}`;

                var parentItemsCount = items.filter((it) => it.itemType == 'action-sub-group' && it.parentId == item.itemData.id).length;

                var newProject = {
                    editing: true,
                    expanded: false,
                    hasItems: true,
                    icon: '',
                    iconColor: '',
                    id: '0',
                    internalId: newGroupName,
                    itemType: 'action-sub-group',
                    parentId: item.itemData.id,
                    text: newGroupName,
                };
                items.push(newProject);
                this.$refs.treeViewEquipments.instance.option('items', items);
                var parentItem = items.find((it) => it.itemType == 'action-group' && it.id == item.itemData.id);
                parentItem.text = `${this.$t('Actions')} (${parentItemsCount + 1})`;
            });
        },
        async globalActionView(item) {
            if (!this.$store.state.auth.user.permissions.PermissionRead) return;
            this.$store.commit('selectActionGlobalView', { Equipment: item.node.parent.itemData.internalId, project: item.node.parent.parent.parent.itemData.internalId });
            // TODO: uery to get tree-form data of actions. For the moment, just display a simple DX flow chart.
            // const actionEquipment = data.parent.text;
            // const projectId = data?.parent?.parent?.parent.id;
            // const res = await ProdComDataSourceService.getDataSource(actionEquipment, projectId);
            // if(res.success == "n") this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(res.ret, res.retParams), 'warning'));
            // else{
            //     if(res.ret == null){
            //         res.ret = {
            //             Equipment: actionEquipment
            //         };
            //     }else{
            //         res.ret.Equipment = actionEquipment;
            //     }
            //     res.ret.ProjectId = projectId;
            // }
        },
        /**
         * Displays the 'NewAction' wizard.
         */
        async addAction(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            this.selectedGroup = item.itemData.internalId;
            this.selectedEquipment = item.node.parent.parent.itemData.internalId;
            this.selectedProject = item.node.parent.parent.parent.parent.itemData.internalId;
            // Execute the onshow after change, because otherwise we have
            // a decoherence in data change...
            this.$nextTick(() => {
                this.$bvModal.show('newAction');
            });
        },
        async deleteGroupAction(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            let dlg = custom({
                title: this.$t('confirmDeleteAllTitle'),
                messageHtml: `<div style="width: 450px;">${this.$t('confirmDelete')}</div>`, // Make sure the title is fully displayed
                buttons: [
                    {
                        text: this.$t('Ok'),
                        onClick: function () {
                            return true;
                        },
                    },
                    {
                        text: this.$t('Cancel'),
                        onClick: function () {
                            return false;
                        },
                    },
                ],
            });
            var resultDialog = await dlg.show();
            if (resultDialog) {
                this.loading = true;
                let selectedEquipment = item.node.parent.parent.itemData.internalId;
                let selectedProject = item.node.parent.parent.parent.parent.itemData.internalId;
                let selectedGroup = item.node.itemData.internalId;

                const result = await ProdComActionService.deleteGroupAction(selectedProject, selectedEquipment, selectedGroup);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                else {
                    // Remove the tab if it exists
                    this.$store.commit('closeTabsForActionGroup', { project: selectedProject, equipment: selectedEquipment, group: selectedGroup });
                    // Action has been deleted, we can refresh the frontend and backend
                    await this.refreshTreeviewEquipments();
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('actions.deleted'), 'info'));
                }
                this.loading = false;
            }
        },
        /**
         * Deletes all actions referenced by its equipment
         */
        async deleteAllAction(equipment) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            let dlg = custom({
                title: this.$t('confirmDeleteAllTitle'),
                messageHtml: `<div style="width: 450px;">${this.$t('confirmDelete')}</div>`, // Make sure the title is fully displayed
                buttons: [
                    {
                        text: this.$t('Ok'),
                        onClick: function () {
                            return true;
                        },
                    },
                    {
                        text: this.$t('Cancel'),
                        onClick: function () {
                            return false;
                        },
                    },
                ],
            });
            var resultDialog = await dlg.show();
            if (resultDialog) {
                this.loading = true;

                let selectedEquipment = equipment.node.parent.itemData.internalId;
                let selectedProject = equipment.node.parent.parent.parent.itemData.internalId;
                const result = await ProdComActionService.deleteAllAction(selectedProject, selectedEquipment);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                else {
                    // Remove the tab if it exists
                    this.$store.commit('closeTabsForEquipment', { Name: selectedEquipment, ProjectId: selectedProject });
                    // Action has been deleted, we can refresh the frontend and backend
                    await this.refreshTreeviewEquipments();
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('actions.deleted'), 'info'));
                }
                this.loading = false;
            }
        },
        /**
         * Deletes the action referenced by its node
         */
        async deleteAction(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            let dlg = custom({
                title: this.$t('confirmDeleteAllTitle'),
                messageHtml: `<div style="width: 450px;">${this.$t('confirmDelete')}</div>`, // Make sure the title is fully displayed
                buttons: [
                    {
                        text: this.$t('Ok'),
                        onClick: function () {
                            return true;
                        },
                    },
                    {
                        text: this.$t('Cancel'),
                        onClick: function () {
                            return false;
                        },
                    },
                ],
            });
            var resultDialog = await dlg.show();
            if (resultDialog) {
                this.loading = true;
                var selectedAction = item.itemData.internalId;
                var selectedGroup = item.node.parent.itemData.internalId;
                var selectedEquipment = item.node.parent.parent.parent.itemData.internalId;
                var selectedProject = item.node.parent.parent.parent.parent.parent.itemData.internalId;
                const result = await ProdComActionService.deleteAction(selectedProject, selectedEquipment, selectedGroup, selectedAction);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                else {
                    // Close any corresponding tab
                    this.$store.commit('closeTabsForAction', { project: selectedProject, equipment: selectedEquipment, action: selectedAction });
                    // Action has been deleted, we can refresh the frontend and backend
                    await this.refreshTreeviewEquipments();
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('action.deleted'), 'info'));
                }
                this.loading = false;
            }
        },
        /**
         * Deletes the data source template referenced by its node
         */
        async deleteDataSource(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            let dlg = custom({
                title: this.$t('confirmDeleteAllTitle'),
                messageHtml: `<div style="width: 450px;">${this.$t('confirmDelete')}</div>`, // Make sure the title is fully displayed
                buttons: [
                    {
                        text: this.$t('Ok'),
                        onClick: function () {
                            return true;
                        },
                    },
                    {
                        text: this.$t('Cancel'),
                        onClick: function () {
                            return false;
                        },
                    },
                ],
            });
            var resultDialog = await dlg.show();
            if (resultDialog) {
                this.loading = true;
                const result = await ProdComDataSourceService.deleteTemplateConnection(item.itemData.internalId, item.node.parent.parent.itemData.internalId);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                else {
                    // Close any corresponding tab
                    this.$store.commit('closeTabsForDataSource', { project: item.node.parent.parent.itemData.internalId, dataSource: item.itemData.internalId });
                    // Data source template has been deleted, we can refresh the frontend and backend
                    await this.refreshTreeviewEquipments();
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('datasources.template.deleted'), 'info'));
                }
                this.loading = false;
            }
        },
        /**
         * Adds a new data source template to the list of templates
         */
        async addDataSourceTemplate(item) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            var items = this.$refs.treeViewEquipments.instance.option('items');
            if (items.findIndex((item) => item.editing) != -1) return;

            var newDataSourceName = this.$t('Data Source');
            var i = 1;
            while (items.findIndex((it) => it.itemType == 'datasource' && it.text === `${newDataSourceName} ${i}`) != -1) {
                i++;
            }

            var parentItemsCount = items.filter((it) => it.itemType == 'datasource' && it.parentId == item.itemData.id).length;
            newDataSourceName = `${newDataSourceName} ${i}`;
            var id = uuid.v4();
            var newDataSource = {
                editing: true,
                expanded: false,
                hasItems: false,
                icon: 'fas fa-database fa-fw',
                iconColor: '#f55385',
                id: item.parentId + '¤¤' + id,
                internalId: id,
                itemType: 'datasource',
                parentId: item.itemData.id,
                text: newDataSourceName,
            };
            items.push(newDataSource);
            this.$refs.treeViewEquipments.instance.option('items', items);
            var parentItem = items.find((it) => it.itemType == 'datasource-group' && it.id == item.itemData.id);
            parentItem.text = `${this.$t('Data Sources')} (${parentItemsCount + 1})`;
        },
        /**
         * Called when the user closes the Project Configuration Wizard (OK event)
         */
        async onProjectWizardConfirm(e) {
            this.loading = true;
            let result = await ProdComProjectService.initialize();
            if (result.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                this.loading = false;
                return;
            }
            await this.refreshTreeviewEquipments();
            this.loading = false;
        },
        /**
         * Called when the user closes the Import Project Configuration Wizard (OK event)
         */
        async onImportProjectWizardConfirm(e) {
            this.refreshTreeviewEquipments();
        },
    },
};
</script>

<style lang="scss">
.conf-treeview .dx-treeview-item {
    min-height: 22px;
    height: 22px;
    padding: 2px 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
    font-size: 13px;
    font-family: Segoe WPC, Segoe UI, sans-serif;
}

.conf-treeview .dx-treeview-toggle-item-visibility {
    height: 22px !important;
}

.conf-treeview .dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item {
    background: #1ca8dd66;
    color: var(--navbar-text-color);
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--navbar-background-color);
    opacity: 0.8;
    z-index: 9999;
}

.loading-spinner {
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
}

.loading-spinner-inner {
    border: 4px solid var(--navbar-text-color);
    border-top: 4px solid var(--navbar-background-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.sidebar-menu-iframe {
    height: calc(100% - 28px);
    margin-top: -60px;
}

.sidebar-menu-iframe-addon {
    background-color: var(--navbar-text-color) !important;
    color: var(--navbar-background-color) !important;
    width: 327px;
    height: 60px;
    padding-top: 20px;
    border-top: 1px solid var(--navbar-background-color);
    position: absolute;
    // width: 320px;
    bottom: 28px;
    z-index: 5555;
    filter: brightness(0.9);
}

.sidebar-menu-iframe-addon .custom-switch {
    padding-left: 70px;
}

#sidebar-menu {
    margin-top: inherit;
    top: 60px;
    height: calc(100% - 88px);
    filter: brightness(0.9);
    box-shadow: none !important;
    background-color: var(--sidebar-background-color) !important;
    color: var(--navbar-text-color) !important;
}

#sidebar-menu button.close {
    color: var(--navbar-background-color) !important;
}

.sidebar-menu-toggler {
    z-index: 1500;
    position: fixed;
    height: 240px;
    width: 10px;
    top: calc(50% - 150px);
    display: block;
    background-color: var(--text-color);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.sidebar-menu-toggler-middle-bar {
    margin-top: 40px;
    height: 160px;
    width: 2px;
    background-color: var(--btn-background-color);
    margin-left: 4px;
}

.public-indicator {
    position: absolute;
    top: 3px;
    left: 3px;
    font-size: 0.8rem;
}

.side-menu-icon {
    opacity: 0.5;
}

.side-menu-icon:hover {
    opacity: 0.7;
}

.side-menu-refresh {
    font-size: 0.78rem;
}

.full-opacity {
    opacity: 0.7;
}

.main-mode-chooser {
    width: 50%;
    outline: none !important;
    box-shadow: none !important;
    color: var(--text-color);
    text-transform: none !important;
}

.main-mode-chooser:focus {
    outline: none !important;
    box-shadow: none !important;
}

.side-menu-button-actions-with-save {
    max-width: 65px !important;
}

.side-menu-button-actions-without-save {
    max-width: 50px !important;
}

.top-menu-icon {
    cursor: pointer;
    transition: all 0.3s;
}

.top-menu-icon:hover {
    transform: scale(1.3);
    transition: all 0.3s;
}
</style>