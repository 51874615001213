<template>

    <b-tabs pills card h-100 d-flex flex-column style="width: 100%">
        <b-tab>
           <template #title>
                <span class="tabHeaderText">{{ $t('Configuration') }}</span>
           </template>

           <div class="tabContent" >
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('opcConnexionType') + ' *'" label-for="connexionType">
                            <b-form-radio-group id="connexionType" v-model="connexionType" :options="connexionTypeOptions" name="connexionType" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <b-form-group :label="$t('equipment.EluCloud.RefreshDelay') + ' *'" label-for="refreshDelay">
                            <b-form-input
                                @change="
                                    (value) => {
                                        if (value > 3600000) {
                                            this.refreshDelay = 3600000;
                                        } else if (value < 1000) {
                                            this.refreshDelay = 1000;
                                        }
                                    }
                                "
                                type="number"
                                min="500"
                                max="3600000"
                                id="refreshDelay"
                                v-model="refreshDelay"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="8">
                        <b-form-group :label="$t('opcXmlUrl')" label-for="url">
                            <b-form-input id="url" v-model="url"  :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite || connexionType === 1}" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="8">
                        <b-button @click="onCheckConnection" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionRead }">
                            {{ $t('equipment.opcua.checkConnection') }}
                            <font-awesome-icon v-if="loadingConnectionCheck" icon="fas fa-spinner-third" class="fa-lg fa-spin-custom" />
                        </b-button>
                    </b-col>
                    
                </b-row>
           </div>
        </b-tab>
        <b-tab>
            <template #title>
                {{ $t('Variables') }}
                <b-badge pill variant="info">{{
                    groups
                        .map((gr) => gr.Items.length)
                        .reduce(function (a, b) {
                            return a + b;
                        }, 0)
                }}</b-badge>
            </template>
            <ItemConfiguration
                ref="itemConfiguration"
                :columns="itemColumns"
                :groups="groups"
                :OnItemBrowse="onItemBrowse"
                :createChildren="createChildren"
                :separateGroupConfig="true"
                :RefreshVarCounter="refreshVariablesCounter"
                :groupConfiguration="groupConfiguration"
                :readonly="!this.$store.state.auth.user.permissions.PermissionWrite"
                :updateMode="updateMode"
            />
        </b-tab>
    </b-tabs>
</template>

<script>
import { uuid } from 'vue-uuid';
import ToastAlert from '@/utils/ToastAlert';

import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ItemConfiguration from '@/components/configuration/Equipments/ItemConfiguration.vue';

export default {
    name: 'Bridge',
    components : {
        ItemConfiguration
    },
    props: {
        equipment: Object,
        project: String,
        updateMode: Boolean,
    },
    data() {
        return {
            connexionTypeOptions: [
                { value: 0, text: this.$t('equipment.bridge.terrain') },
                { value: 1, text: this.$t('equipment.bridge.cloud') },
            ],
            connexionType: (this.equipment?.IsCloud ? 1 : 0 ) ?? 0,
            loadingConnectionCheck: false,
            refreshDelay: this.equipment?.refreshDelay ?? 60000,
            itemColumns: [
                { key: 'Name', dataField: 'Name', dataType: 'string', caption: this.$t('Name'), required: true },
                { key: 'Type', dataField: 'Type', dataType: 'string', caption: this.$t('Type'), required: true, width: 120 },
                { key: 'Address', dataField: 'Address', dataType: 'string', caption: this.$t('Address'), required: true },
            ],
            Name :this.equipment?.Name ?? "",
            url : this.equipment?.Url ?? "",
            groups: this.equipment?.Groups ?? [{ id: uuid.v4(), Name: `${this.$t('Group')} 1`, Active: true, RefreshRate: 500, Items: [] }],
            groupConfiguration: {
                rows: [
                    {
                        columns: [{ text: this.$t('Refresh rate'), variable: 'RefreshRate', inputType: 'number', cols: '6', min: '100', max: '99999', defaultValue: 500 }],
                    },
                ],
            },
        };
    },
    async mounted() {
    },
    methods: {
        async saveEquipmentCommunication() {
            //await this.$refs.itemConfiguration.saveEquipmentCommunication();
        },
        /**
         * Synchronizes the data changes from the wizard into the given equipment. This method should be called before updateing / saving / adding
         * the equipment in the parent control.
         * @public This method is public.
         */
        async synchronizeEquipment() {
            this.equipment.Groups = this.groups;
            this.equipment.refreshDelay = this.refreshDelay;
            this.equipment.Url = this.url;
            this.equipment.IsCloud = this.getCloudBool();
            this.equipment.ProjectId = this.project;
            this.equipment.Name = this.Name;
        },
        async refreshVariablesCounter(searchingForItems, variableCounter) {
        },
        /**
         * Checks if the given connection parameters result in a valid connection.
         */
        validate() {
            return {
                refreshDelay: this.refreshDelay,
                Name: this.Name,
                Url: this.url,
                IsCloud : this.getCloudBool(),
                ProjectId : this.project
            };
        },
        async onEntityChange(e) {
           
        },

        getCloudBool(){
            return this.connexionType == 1;
        },
        /**
         * Checks if the given connection parameters result in a valid connection.
         */
        async onCheckConnection(e) {
            this.loadingConnectionCheck = true;
            const result = await ProdComEquipmentService.BridgeCheckConnection(this.validate());
            if (result.ret.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.Bridge.failedToConnect'), 'warning'));
            } else {
                console.log(result)
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.Bridge.connectionSuccess'), 'success', this.$t('ConnectionSuccess')));
            }
            this.loadingConnectionCheck = false;
        },
        /**
         * Called when browsing for items in configuration. Returns the list of available items
         */
        async onItemBrowse(e) {
            try {
                const data = await ProdComEquipmentService.bridgeBrowseItems(this.validate());
                if (data.success == 'n') {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    return [];
                } else {
                    return data.ret;
                }
                return ;
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        async createChildren(parent, treeviewInstance) {
            if (parent == null)
                return [
                    {
                        Name: 'Objects',
                        Address: 0,
                        Type: '',
                        IsFolder: true,
                        Active: true,
                        expanded: true,
                    },
                ];
            try {

                let upperParent = parent?.parent?.itemData?.Name ?? null;
                const data = await ProdComEquipmentService.bridgeBrowseNodeItems(this.validate(), parent.itemData.Name, upperParent);

                if (data.success == 'n') {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    return [];
                } else {
                    if (parent.key == 0) {
                        parent.itemData.ChildsCount = data.ret.length;
                        treeviewInstance.repaint();
                    }
                    let ret = data.ret.map((d) => {
                        d.parentId = parent.itemData.Address;
                        d.expanded = data.ret.length == 1;
                        d.disabled = !d.IsFolder && (d.Type == null || d.Type === '');
                        return d;
                    });
                    return ret;
                }
            } catch (error) {
                console.log(error);
                return [];
            }
        },
    },
    
};
</script>