<template>
    <b-modal :id="id" v-b-modal.modal-multi-2 size="xxl" @show="onItemShow" @cancel="onItemCancel" @ok="onItemOk" :title="$t('Search items')" :cancel-title="$t('Cancel')" :ok-title="$t('Ok')">
        <b-form>
            <b-row class="d-flex">
                <b-col class="flex-grow-1">
                    <b-card class="card-items-scroll">
                        <DxTreeView
                            id="treeview-items"
                            ref="treeviewItems"
                            class="conf-treeview"
                            :create-children="createChildrenHandler"
                            :root-value="''"
                            :expand-nodes-recursive="false"
                            data-structure="plain"
                            item-template="item-template"
                            keyExpr="Address"
                            hasItemsExpr="IsFolder"
                            displayExpr="Name"
                            @item-click="onItemClick"
                            @item-expanded="onItemExpandedOrCollapsed"
                            @item-collapsed="onItemExpandedOrCollapsed"
                            selectItemByClick="true"
                            selectionMode="single"
                        >
                            <template #item-template="item">
                                <div>
                                    <font-awesome-icon v-if="item.data.IsFolder" icon="folder" color="rgb(255, 227, 158)" fixed-width />
                                    <font-awesome-icon v-else icon="tag" :color="item.data.Type == null || item.data.Type === '' ? '#A9A9A9' : '#f57c00'" fixed-width />
                                    {{ item.data.Name }} {{ item.data.ChildsCount && item.data.ChildsCount != '' ? `(${item.data.ChildsCount})` : '' }}
                                </div>
                            </template>
                        </DxTreeView>
                    </b-card>
                </b-col>
                <b-col class="fixed-width">
                    <b-container class="h-100">
                        <b-row class="h-100">
                            <b-col class="h-100 d-table">
                                <div class="text-center d-table-cell align-middle">
                                    <button type="button" class="btn btn-primary mb-2" :disabled="selectedItemServer == null || selectedItemServer.length === 0" v-on:click="addSelectedItems">
                                        <font-awesome-icon icon="fas fa-arrow-right" size="lg" />
                                    </button>
                                    <br />
                                    <button type="button" class="btn btn-primary mt-2" :disabled="selectedItemClient == null || selectedItemClient.length === 0" v-on:click="removeSelectedItems">
                                        <font-awesome-icon icon="fas fa-arrow-left" size="lg" />
                                    </button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>
                <b-col class="flex-grow-1">
                    <b-card class="card-items-scroll">
                        <div class="selected-items-counter" v-if="itemListClient.length > 0">
                            <span class="variable-counter">{{ itemListClient.length }} {{ $t('Selected items') }}</span>
                        </div>
                        <DxList id="list-items" noDataText="" ref="listItems" class="conf-list" :items="itemListClient" keyExpr="id" @selectionChanged="onSelectItemClient" selectionMode="all" :showSelectionControls="true">
                            <template #item="item">
                                <div>
                                    <font-awesome-icon icon="tag" color="rgb(245, 124, 0)" fixed-width />
                                    {{ item.data.text }} ({{ item.data.id }})
                                </div>
                            </template>
                        </DxList>
                    </b-card>
                </b-col>
            </b-row>
        </b-form>
    </b-modal>
</template>
<script>
import DxTreeView from 'devextreme-vue/tree-view';
import DxList from 'devextreme-vue/list';

export default {
    name: 'SelectItemListWizard',
    components: {
        DxTreeView,
        DxList,
    },
    props: {
        OnLoad: Function,
        createChildren: Function,
        OnOk: Function,
        OnCancel: Function,
        id: String,
    },
    data() {
        return {
            searchingForItems: { is: false },
            itemListServer: [],
            selectedItemServer: null,
            itemListClient: [],
            selectedItemClient: [],
        };
    },
    methods: {
        createChildrenHandler(parent) {
            if (this.createChildren != null) {
                return this.createChildren(parent, this.$refs.treeviewItems.instance);
            }
        },
        // getFullPathName exclude the root node
        getFullPathName(item) {
            let path = '';
            let parent = item.parent;
            while (parent != null && parent.parent != null) {
                path = parent.itemData.Name + '_' + path;
                parent = parent.parent;
            }
            return path + item.itemData.Name;
        },
        async onItemExpandedOrCollapsed(e) {
            this.selectedItemServer = [e.node];
        },
        async onItemShow(e) {
            this.itemListClient = [];
            if (this.OnLoad != null) {
                this.itemListClient = await this.OnLoad();
            }
        },
        async onItemCancel(e) {
            if (this.OnCancel != null) this.OnCancel();
        },
        async onItemOk(e) {
            if (this.OnOk != null) this.OnOk(this.itemListClient);
        },
        async onItemClick(e) {
            this.selectedItemServer = [e.node];
        },
        async addSelectedItemRecurse(items, destination, path) {
            if (items == null) return;
            items.forEach((item) => {
                if (item.items != null && item.items.length > 0) {
                    this.addSelectedItemRecurse(item.items, destination);
                } else if ((item.itemData.IsFolder == null || item.itemData.IsFolder === false) && destination.findIndex((it) => it.id === item.key) == -1 && !item.disabled) {
                    const itemFullPathName = this.getFullPathName(item);
                    item.itemData.itemFullPathName = itemFullPathName;
                    destination.push({ id: item.key, text: itemFullPathName, icon: 'fas fa-tag', iconColor: '#f57c00', itemData: item.itemData });
                }
            });
        },
        async onSelectItemClient(e) {
            const addedItems = e.addedItems;
            const removedItems = e.removedItems;
            for (const i of addedItems) {
                const itemInSelection = this.selectedItemClient.findIndex((it) => it.id === i.id);
                if (itemInSelection < 0) this.selectedItemClient.push(i);
            }
            for (const i of removedItems) {
                const itemInSelection = this.selectedItemClient.findIndex((it) => it.id === i.id);
                if (itemInSelection >= 0) this.selectedItemClient.splice(itemInSelection, 1);
            }
        },
        async addSelectedItems(e) {
            this.addSelectedItemRecurse(this.selectedItemServer, this.itemListClient);
            this.selectedItemServer = [];
        },
        async removeSelectedItems(e) {
            this.itemListClient = this.itemListClient.filter((item) => this.selectedItemClient.findIndex((it) => it.id === item.id) == -1);
            this.selectedItemClient = [];
            this.$refs.listItems.instance.unselectAll();
        },
    },
};
</script>
<style lang="scss">
.fixed-width {
    width: 100px !important;
    max-width: 100px !important;
    flex: 0 0 auto;
}
.card-items-scroll {
    max-height: 400px;
    height: 400px;
    min-height: 400px;
    overflow-y: auto;
}
.variable-counter {
    margin-left: 0.5em; /* Ajouter une marge à gauche pour l'espace entre l'icône et la div */
}

.conf-list .dx-list-item {
    border: none;
    min-height: 22px !important;
    max-height: 22px !important;
    height: 22px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
    font-size: 13px;
    font-family: Segoe WPC, Segoe UI, sans-serif;
}
.conf-list.dx-empty-message,
.dx-list-item-content {
    min-height: 22px !important;
    max-height: 22px !important;
    height: 22px !important;
    padding: 2px 2px;
}
.conf-list .dx-checkbox-icon {
    width: 16px;
    height: 16px;
}
.conf-list .dx-list-select-all-label {
    margin-top: -3px;
}
.conf-list .dx-list-select-all {
    padding: 4px 0 4px;
}
.selected-items-counter {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 0.5em;
    margin-top: 0.5em;
    font-size: 12px;
    font-family: Segoe WPC, Segoe UI, sans-serif;
}
</style>