var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tabs',{staticStyle:{"width":"100%"},attrs:{"pills":"","card":"","h-100":"","d-flex":"","flex-column":""}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"tabHeaderText"},[_vm._v(_vm._s(_vm.$t('Configuration')))])]},proxy:true}])},[_c('div',{staticClass:"tabContent"},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('opcConnexionType') + ' *',"label-for":"connexionType"}},[_c('b-form-radio-group',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"connexionType","options":_vm.connexionTypeOptions,"name":"connexionType"},model:{value:(_vm.connexionType),callback:function ($$v) {_vm.connexionType=$$v},expression:"connexionType"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.EluCloud.RefreshDelay') + ' *',"label-for":"refreshDelay"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"type":"number","min":"500","max":"3600000","id":"refreshDelay"},on:{"change":(value) => {
                                    if (value > 3600000) {
                                        this.refreshDelay = 3600000;
                                    } else if (value < 1000) {
                                        this.refreshDelay = 1000;
                                    }
                                }},model:{value:(_vm.refreshDelay),callback:function ($$v) {_vm.refreshDelay=$$v},expression:"refreshDelay"}})],1)],1),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":_vm.$t('opcXmlUrl'),"label-for":"url"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite || _vm.connexionType === 1},attrs:{"id":"url"},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-button',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionRead },on:{"click":_vm.onCheckConnection}},[_vm._v(" "+_vm._s(_vm.$t('equipment.opcua.checkConnection'))+" "),(_vm.loadingConnectionCheck)?_c('font-awesome-icon',{staticClass:"fa-lg fa-spin-custom",attrs:{"icon":"fas fa-spinner-third"}}):_vm._e()],1)],1)],1)],1)]),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Variables'))+" "),_c('b-badge',{attrs:{"pill":"","variant":"info"}},[_vm._v(_vm._s(_vm.groups .map((gr) => gr.Items.length) .reduce(function (a, b) { return a + b; }, 0)))])]},proxy:true}])},[_c('ItemConfiguration',{ref:"itemConfiguration",attrs:{"columns":_vm.itemColumns,"groups":_vm.groups,"OnItemBrowse":_vm.onItemBrowse,"createChildren":_vm.createChildren,"separateGroupConfig":true,"RefreshVarCounter":_vm.refreshVariablesCounter,"groupConfiguration":_vm.groupConfiguration,"readonly":!this.$store.state.auth.user.permissions.PermissionWrite,"updateMode":_vm.updateMode}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }